import type { NuxtAxiosInstance } from '@nuxtjs/axios'
import type { Context } from '@nuxt/types'
import { ThreadPathParams } from '~/services/types/Thread'
export default class SmsService {
  $axios: NuxtAxiosInstance

  constructor (context: Context) {
    this.$axios = context.$axios
  }

  public scheduleMessage ({ tenantId, phoneNumberId, threadId }: ThreadPathParams, message2Schedule: string) {
    return this.$axios.$post(`/api/scheduled-sms/${tenantId}/${phoneNumberId}/${threadId}`, message2Schedule)
  }

  public deleteScheduleMessage ({ tenantId, phoneNumberId, threadId }: ThreadPathParams, messageId: string) {
    return this.$axios.$delete(`/api/scheduled-sms/${tenantId}/${phoneNumberId}/${threadId}/${messageId}`)
  }
}
